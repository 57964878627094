import gsap from "gsap/all";
import { qs, qsa } from "../utils/lib";

export const checkPage = () => {
  if (qs(".articles")) {
    qs(".navbar_cta.arrow").classList.remove("hide");
    // qs(".navbar_cta.discover").classList.add("hide");
  } else if (qs(".home")) {
    // qs(".navbar_cta.discover").classList.remove("hide");
  } else if (qs(".discover_more")) {
    // qs(".navbar_cta.discover").classList.add("hide");
  }

  if (qs(".index")) {
    qs(".navbar_cta.discover").classList.add("hide");
  }

  // if (qs(".home-condensed-glow")) {
  //   qs(".navbar_cta.discover").classList.add("glow");
  // }

  if (qs(".discover_more") || qs(".articles")) {
    qs(".navbar_logo-cont").addEventListener(
      "click",
      () => (location.href = "/#decrypted")
    );
  }



  if (qs(".discover_more")) {
    qs(".sort-title").addEventListener("click", () => {
      qs(".sort-title").classList.toggle("rotateImg");
    });

    qs(".sort-data").addEventListener("click", () => {
      qs(".sort-data").classList.toggle("rotateImg");
    });
  }
};


