import "../../scss/application.scss";
import { checkPage, switchCrypted } from "../components/header";
import { qs, qsa } from "../utils/lib";
import Station, { fetchData } from "../components/station";
import StationHover from "../components/station-hover";
import Download from "../components/download";
import Accordion from "../components/accordion";
import Crypto from "../components/crypto";
import gsap from "gsap/all";

document.addEventListener("DOMContentLoaded", () => {
  if (qs(".white-layer"))
    [
      gsap.to(qs(".white-layer"), {
        autoAlpha: 0,
        duration: 1,
        ease: "Power3.easeOut",
        delay: 1,
      }),
    ];

  const home = qs(".home");
  home && home.setAttribute("data-prevpage", "false");
  // switchCrypted();
  checkPage();

  if (qs(".home")) {
    const crypto = new Crypto();
    crypto.init();
  }

  if (qsa(".js-accordion")) {
    const accordions = qsa(".js-accordion");
    accordions.forEach((acc) => {
      const _acc = new Accordion(acc);
      _acc.init();
    });
  }
  if (qsa(".js-station")) {
    const stations = qsa(".js-station");
    stations.forEach((el) => {
      const _station = new Station(el);
      _station.init();
    });
  }

  if (qsa(".js-hover")) {
    const stat_hover = qsa(".js-hover");
    stat_hover.forEach((el) => {
      const _station_hover = new StationHover(el);
      _station_hover.init();
    });
  }

  const station_download = qsa(".js-download");
  if (station_download) {
    station_download.forEach((svg) => {
      const _station_d = new Download(svg);
      _station_d.init();
    });
  }
});
