import { qs, qsa } from "../utils/lib";
import Legend from "../components/legend";
import gsap from "gsap/all";
export default class StationHover {
  constructor(el) {
    this.station = el;
    this.station_info = qs(".station_names", el);
    this.texts = qsa("text", el);
    this.symbols = qsa(".js_symbol", el);
    this.symbol_name = qs(".js_symbol-name", el);
    this.symbol_value = qs(".js_symbol-value", el);
    this.station_svg = qs(".parent-svg", el);
    this.visibility = qs(".js_visibility-parent", el);
    this.visibility_int = qs(["#visibility-int"], el);
    this.visibility_children = qsa(".js_visibility-child", el);
    this.isClicked = false;
    this.touchStamp = "";
    this.currentTime = "";
    // this.list_els = qsa(".l_el", el)
  }

  init() {
    // if (window.innerWidth < 1024) {
    //     this.list_els.forEach((l_el) => {
    //         const _legend = new Legend(l_el);
    //         _legend.init();
    //     })

    // } else {
    //     this.handleHover("mouseenter")
    //     this.handleHover("touchstart")
    // }
    gsap.to(this.station_info, {
      autoAlpha: 0,
      duration: 0,
    });

    this.handleStationInfoMobile();
    this.resetStationMobile();
    this.handleStationInfo("mouseleave", 0);
    this.handleStationInfo("mouseenter", 1);
    this.handleHover("mouseenter");
    this.handleHover("touchstart");
  }

  handleHover(startEvent) {
    this.symbols.forEach((symbol) => {
      symbol.addEventListener(startEvent, () => {
        // change text
        this.symbol_name.innerHTML = symbol.dataset.name;
        if (symbol.lastElementChild.nodeName === "text") {
          if (symbol.dataset.cloud) {
            this.symbol_value.innerHTML = symbol.dataset.cloud;
          } else if (symbol.dataset.visibility) {
            this.symbol_value.innerHTML = symbol.dataset.visibility;
          } else if (symbol.dataset.pressure) {
            this.symbol_value.innerHTML = symbol.dataset.pressure;
          } else if (symbol.dataset.temperature) {
            this.symbol_value.innerHTML = symbol.dataset.temperature;
          } else if (symbol.dataset.dewPoint) {
            this.symbol_value.innerHTML = symbol.dataset.dewPoint;
          } else {
            this.symbol_value.innerHTML = symbol.lastElementChild.innerHTML;
          }
        } else {
          this.symbol_value.innerHTML = symbol.dataset.value;
        }

        // change color
        if (qs(".home")) {
          return;
        } else {
          this.symbols.forEach((symbol) => {
            symbol.firstElementChild.style.fillOpacity = "0";
          });
          symbol.firstElementChild.style.fill = "#a9c23f";
          symbol.firstElementChild.style.fillOpacity = "1";
        }
      });
    });
    this.station_svg.addEventListener("mouseleave", () => {
      this.symbols.forEach((symbol) => {
        symbol.firstElementChild.style.fillOpacity = "0";
      });
    });
  }

  handleStationInfo(trigger, direction) {
    this.station_svg.addEventListener(trigger, () => {
      gsap.to(this.station_info, {
        autoAlpha: direction,
        duration: 0.1,
      });
    });
  }
  handleStationInfoMobile() {
    this.station.addEventListener("touchstart", (e) => {
      gsap.to(this.station_info, {
        autoAlpha: 1,
        duration: 0.1,
      });
      this.touchStamp = new Date().getTime();
    });
  }

  resetStationMobile() {
    setInterval(() => {
      this.currentTime = new Date().getTime();
      // check if we're on mobile device
      if (this.touchStamp === "") {
        return;
      } else {
        if (this.currentTime - this.touchStamp > 5000) {
          gsap.to(this.station_info, {
            autoAlpha: 0,
            duration: 0.1,
          });
        } else {
          return;
        }
      }
    }, 2000);
  }
}
