import { qs, qsa } from "../utils/lib";
import gsap from "gsap/all";

export default class Accordion {
  constructor(el) {
    this.accordion = el;
    this.summary = el.firstElementChild;
    this.detail = el.lastElementChild;
    this.isOpen = false;
  }
  init() {
    this.setAccordion();
    this.summary.addEventListener("click", () => {
      this.handleClick();
    });
  }
  setAccordion() {
    gsap.to(this.detail, {
      height: 0,
      autoAlpha: 0,
      duration: 0,
    });
  }
  handleClick() {
    const arrow = qs("svg", this.summary);
    if (this.isOpen == false) {
      gsap.to(this.detail, {
        height: "auto",
        autoAlpha: 1,
        duration: 0.5,
        ease: "Circ.easeInOut",
        onComplete: () => {
          this.isOpen = true;
        },
      });
      gsap.to(arrow, {
        fill: "black",
        rotate: "90deg",
      });
    } else {
      gsap.to(this.detail, {
        height: 0,
        autoAlpha: 0,
        duration: 0.5,
        ease: "Circ.easeInOut",
        onComplete: () => {
          this.isOpen = false;
        },
      });
      gsap.to(arrow, {
        fill: "none",
        rotate: "0deg",
      });
    }
  }
}
