import gsap, { TextPlugin } from "gsap/all";
import { qs, qsa } from "../utils/lib";
gsap.registerPlugin(TextPlugin);
import Scrambler from "./scrabledText/scrambler";

export default class Station {
  constructor(el) {
    this.station_page = el;
    this.input_date = qs(".js-station-date");
    this.input_icao = qs(".js-station-icao");
    this.data = JSON.parse(el.dataset.weather);
    this.send_btn = qs('.js-station-send')
    // font-weight global variable
    this.weight = "light";
    this.download_btn = qs(".download-button", el);
    this.w_buttons = qsa(".weight-button", el);
    this.texts = qsa("text", el);
    this.symbols = qsa(".js_symbol", el);
    this.symbol_name = qs(".js_symbol-name", el);
    this.symbol_value = qs(".js_symbol-value", el);
    this.station_svg = qsa(["#parent-svg"], el);
    this.flag = document.querySelector(".flag");
    this.station = "";
    this.dewPoint = "OKOM";
    this.temperature = "OKOM";
    this.windDirection = "";
    this.pressure = "OKOM";
    this.pressureTendency = "";
    this.tendencyRes = "";
    this.tendencyHover = "";
    this.windSpeed = "OKOM";
    this.windSpeedRes = "OKOM";
    this.wheater = "";
    this.weatherRes = "";
    this.weatherHover = "No significant weather";
    //Visibility 9999 = 10km or more, 8000 = 8km, 0400 = 400m, 0000 = -50metri
    this.visibility = "OKOM";
    this.visibilityRes = "";
    this.totClouds = 0;
    this.cloudsType = "";
    this.cloudsFeet = "";
    this.skyRes = "";
    this.lowCloudAltitude = null;
    this.middleCloudAltitude = null;
    this.highCloudAltitude = null;
    this.lowCloudType = "No significant cloud";
    this.middleCloudType = "No significant cloud";
    this.highCloudType = "No significant cloud";

    this.inputs_area = qs(".inputs-area")
    this.refresh_btn = qs(".js-station-refresh")
    this.after_create_buttons = qsa(".after-create-buttons")
  }

  init() {
    this.stationData(this.data);
    this.send_btn &&
      this.send_btn.addEventListener('click', () => {
        this.fetchData()
        setTimeout(() => {
          this.send_btn.classList.add("hidden")
          this.inputs_area.classList.add("deactivated")
          this.after_create_buttons.forEach((btn) => {
            btn.classList.add("active")
          })
        }, 1800)
      })
    this.refresh_btn && this.refresh_btn.addEventListener("click", () => {
      window.location.reload()
    })
  }

  async fetchData() {
    let input_icao = this.input_icao?.value
    let input_date = this.input_date?.value
    let url = `https://history.avwx.rest/api/metar/${input_icao}?token=dev-EgIVL29Hfd5olbrA5G3aPMFpMRudMQXldKLprJs&date=${input_date}&parse=true&format=json`;
    const response = await fetch(url);
    const data = await response.json();
    if (response.status !== 400) {
      const time_hour = qs('input[type=radio]:checked')
      if (time_hour.value == 'am') {
        this.data = await data.results.at(-1);
      } else {
        this.data = await data.results.at(0);
      }
      // reset svg
      this.totClouds = 0;
      qs("#low-cover").innerHTML = "NSC";
      qs("#high-cover").innerHTML = "NSC";
      qs("#medium-cover").innerHTML = "NSC";
      this.weatherRes = "null";
      this.stationData(await this.data);


    } else {
      alert(data.error)
    }

  }
  stationData = (data) => {
    console.log(data)
    this.station = data.station;
    this.dewPoint = data.dewpoint.value;
    this.temperature = data.temperature.value;
    this.windDirection = data.wind_direction ? data.wind_direction.value : "";
    this.pressure = data.altimeter.value;
    this.pressureTendency = data.remarks_info && data.remarks_info.pressure_tendency;
    this.windSpeed = data.wind_speed ? data.wind_speed.value : 0;

    qs("#station-name").innerHTML = data.station
    if (this.pressureTendency === null) {
      this.tendencyRes = "Steady";
      this.tendencyHover = "Steady";
    }
    if (data.visibility.value === 0) {
      this.visibility = "F00";
      this.visibilityRes = "Less than 50 metres ";
    } else if (data.visibility.value > 0 && data.visibility.value < 1000) {
      this.visibility = `F0${(data.visibility.value - (data.visibility.value % 100)) / 100}`;
      this.visibilityRes = `${parseInt(data.visibility.repr, 10)} meters`;
    } else if (data.visibility.value === 9999) {
      this.visibility = ">59";
      this.visibilityRes = "Greater than or equal to 10,000 metres";
    } else if (data.visibility.value >= 9000) {
      this.visibility = "59";
      this.visibilityRes = "Greater than or equal to 9,000 metres";
    } else if (data.visibility.value >= 1000 && data.visibility.value <= 8999) {
      this.visibility = `${(data.visibility.value - (data.visibility.value % 100)) / 100}`;
      this.visibilityRes = `${data.visibility.value} meters`;
    }

    //Temperature
    qs("#air-temperature").innerHTML = this.temperature;
    qs("#air-temperature").parentNode.dataset.temperature = this.temperature + "°";

    //Dew
    qs("#dew-point").innerHTML = this.dewPoint;
    qs("#dew-point").parentNode.dataset.dewPoint = this.dewPoint + "°";

    // visibility
    qs("#visibility").innerHTML = this.visibility;
    qs("#visibility").parentNode.dataset.visibility = this.visibilityRes;

    // pressure
    qs("#sea-level-pressure").innerHTML = this.pressure;
    qs("#sea-level-pressure").parentNode.dataset.pressure = this.pressure + " hPa";

    //Flag
    if (this.windDirection) {
      gsap.to(this.flag, {
        attr: { transform: `rotate(${this.windDirection}, 215, 215)` },
        duration: 0,
      });
    } else {
      gsap.to(this.flag, {
        attr: { transform: `rotate(0, 215, 215)` },
        duration: 0,
      });
    }

    //Visibility
    //Visibility 9999 = 10km or more, 8000 = 8km, 0400 = 400m, 0000 = -50metri

    if (this.windSpeed === 0) {
      this.windSpeedRes = null;
    } else if (this.windSpeed >= 1 && this.windSpeed <= 2) {
      this.windSpeedRes = "1-2-Knots";
    } else if (this.windSpeed >= 3 && this.windSpeed <= 7) {
      this.windSpeedRes = "3-7-Knots";
    } else if (this.windSpeed >= 8 && this.windSpeed <= 12) {
      this.windSpeedRes = "8-12-Knots";
    } else if (this.windSpeed >= 13 && this.windSpeed <= 17) {
      this.windSpeedRes = "13-17-Knots";
    } else if (this.windSpeed >= 18 && this.windSpeed <= 22) {
      this.windSpeedRes = "18-22-Knots";
    } else if (this.windSpeed >= 23 && this.windSpeed <= 27) {
      this.windSpeedRes = "23-27-Knots";
    } else if (this.windSpeed >= 28 && this.windSpeed <= 32) {
      this.windSpeedRes = "28-32-Knots";
    } else if (this.windSpeed >= 33 && this.windSpeed <= 37) {
      this.windSpeedRes = "33-37-Knots";
    } else if (this.windSpeed >= 38 && this.windSpeed <= 42) {
      this.windSpeedRes = "38-42-Knots";
    } else if (this.windSpeed >= 43 && this.windSpeed <= 47) {
      this.windSpeedRes = "43-47-Knots";
    } else if (this.windSpeed >= 48 && this.windSpeed <= 52) {
      this.windSpeedRes = "48-52-Knots";
    } else if (this.windSpeed >= 53 && this.windSpeed <= 57) {
      this.windSpeedRes = "53-57-Knots";
    } else if (this.windSpeed >= 58 && this.windSpeed <= 62) {
      this.windSpeedRes = "58-62-Knots";
    } else if (this.windSpeed >= 63 && this.windSpeed <= 67) {
      this.windSpeedRes = "63-67-Knots";
    } else if (this.windSpeed >= 68 && this.windSpeed <= 72) {
      this.windSpeedRes = "68-72-Knots";
    } else if (this.windSpeed >= 73 && this.windSpeed <= 77) {
      this.windSpeedRes = "73-77-Knots";
    } else if (this.windSpeed >= 78 && this.windSpeed <= 82) {
      this.windSpeedRes = "78-82-Knots";
    } else if (this.windSpeed >= 83 && this.windSpeed <= 87) {
      this.windSpeedRes = "83-87-Knots";
    } else if (this.windSpeed >= 88 && this.windSpeed <= 92) {
      this.windSpeedRes = "88-92-Knots";
    } else if (this.windSpeed >= 93 && this.windSpeed <= 97) {
      this.windSpeedRes = "93-97-Knots";
    } else if (this.windSpeed >= 98 && this.windSpeed <= 102) {
      this.windSpeedRes = "98-102-Knots";
    }

    //Wheater
    if (data.wx_codes.length >= 1) {
      switch (data.wx_codes[0].repr) {
        case "BR" || "-BR" || "+BR":
          this.weatherRes = "Fog";
          this.weatherHover = "Fog";
          break;

        case "-DZ":
          this.weatherRes = "Light-Drizzle";
          this.weatherHover = "Light-Drizzle";
          break;
        case "DZ":
          this.weatherRes = "Moderate-Drizzle";
          this.weatherHover = "Moderate-Drizzle";
          break;
        case "+DZ":
          this.weatherRes = "Heavy-Drizzle";
          this.weatherHover = "Heavy-Drizzle";
          break;
        case "FZDZ":
          this.weatherRes = "Freezing-Drizzle";
          this.weatherHover = "Freezing-Drizzle";
          break;

        case "FG":
          this.weatherRes = "Fog";
          this.weatherHover = "Fog";
          break;
        case "FZFG":
          this.weatherRes = "Fog";
          this.weatherHover = "Freezing Fog";
          break;

        case "-FC" || "FC" || "+FC":
          this.weatherRes = "Funnel Cloud";
          break;

        case "-FU" || "FU" || "+FU":
          this.weatherRes = "Smoke";
          this.weatherHover = "Smoke";
          break;

        case "-GR" || "GR" || "+GR":
          this.weatherHover = "Hail";
          break;
        case "-GS" || "GS" || "+GS":
          this.weatherHover = "Small Hail";
          break;

        case "-HZ" || "HZ" || "+HZ":
          this.weatherRes = "Haze";
          this.weatherHover = "Haze";
          break;

        case "-IC" || "IC" || "+IC":
          this.weatherHover = "Ice Crystals";
          break;

        case "-PL" || "PL" || "+PL":
          this.weatherRes = "Sleet";
          this.weatherHover = "Sleet";
          break;
        case "-RA":
          this.weatherRes = "Light-Rain";
          this.weatherHover = "Light-Rain";
          break;
        case "RA":
          this.weatherRes = "Moderate-Rain";
          this.weatherHover = "Moderate-Rain";
          break;
        case "+RA":
          this.weatherRes = "Heavy-Rain";
          this.weatherHover = "Heavy-Rain";
          break;
        case "SHRA":
          this.weatherRes = "Rain-Shower";
          this.weatherHover = "Rain-Shower";
          break;
        case "FZRA":
          this.weatherRes = "Freezing-Rain";
          this.weatherHover = "Freezing-Rain";
          break;

        case "-SA" || "SA" || "+SA":
          this.weatherHover = "Sand";
          break;

        case "-SG" || "SG" || "+SG":
          this.weatherHover = "Snow Grains";
          break;

        case "-SN":
          this.weatherRes = "Light-Snow";
          this.weatherHover = "Light-Snow";
          break;
        case "SN":
          this.weatherRes = "Moderate-Snow";
          this.weatherHover = "Moderate-Snow";
          break;
        case "+SN":
          this.weatherRes = "Heavy-Snow";
          this.weatherHover = "Heavy-Snow";
          break;
        case "SHSN":
          this.weatherRes = "Snow-Shower";
          this.weatherHover = "Snow-Shower";
          break;
        case "BLSN":
          this.weatherRes = "Blowing-Snow";
          this.weatherHover = "Blowing-Snow";
          break;
        case "BCBLSN":
          this.weatherRes = "Blowing-Snow";
          this.weatherHover = "Patchy-Blowing-Snow";
          break;
        case "-TS" || "TS" || "+TS":
          this.weatherRes = "Thunderstorm";
          this.weatherHover = "Thunderstorm";
          break;
        case "BLDU" || "BLSA":
          this.weatherRes = "Blowing-Dust";
          this.weatherHover = "Blowing-Dust";
          break;
        case "+RATS":
          this.weatherRes = "Thunderstorm";
          this.weatherHover = "Heavy Rain Thunderstorm";
          break;
        default:
          this.weatherRes = null;
          this.weatherHover = "No significant weather";
          break;
      }
    }

    //Clouds
    // const targets = ["#low-cover", "#middle-cover", "#high-cover"];
    function altitudeFormatter(num) {
      if (num >= 0 && num <= 9) {
        return parseInt(num, 10) * 100;
      } else if (num >= 10) {
        return num * 100;
      }
      // } else if (num >= 56 && num <= 59 ) {
      //   return((`${num}`.replace(/^5+/, ''))* 1000)
      // } else if (num >= 60 && num <= 69 ) {
      //   return((`${num}`.replace(/^6+/, '1'))* 1000)
      // } else if (num >= 70 && num <= 79 ) {
      //   return((`${num}`.replace(/^7+/, '2'))* 1000)
      // }
    }

    data.clouds.forEach((cloud, i) => {
      const altitude = cloud.altitude ? cloud.altitude : cloud.base
      if (altitude === undefined) {
        console.log("wrong altitude data in clouds[]")
      }
      if (altitude >= 0 && altitude <= 56) {
        this.lowCloudAltitude = altitude;
        switch (cloud.type) {
          case "NCD":
            this.totClouds = this.totClouds + 0;
            this.lowCloudType = "No significant cloud";
            break;
          case "FEW":
            this.totClouds = this.totClouds + 1;
            this.lowCloudType = "Few clouds";
            break;
          case "SCT":
            this.totClouds = this.totClouds + 3;
            this.lowCloudType = "Scattered clouds";
            break;
          case "BKN":
            this.totClouds = this.totClouds + 5;
            this.lowCloudType = "Broken clouds";
            break;
          case "OVC":
            this.totClouds = this.totClouds + 8;
            this.lowCloudType = "Overcast clouds";
            break;
          case "CLR":
            this.totClouds = this.totClouds + 0;
            this.lowCloudType = "No cloud cover detected below the 12,000 ft";
            break;
          default:
            this.lowCloudType = "No significant cloud";
            this.totClouds = 0;
            break;
        }
        if (cloud.repr.includes("///")) {
          const new_cloud_repr = cloud.repr.replace("///", "")
          console.log(new_cloud_repr)
          qs("#low-cover").innerHTML = new_cloud_repr;
        } else {
          qs("#low-cover").innerHTML = cloud.repr;
        }
        qs("#low-cover").parentNode.dataset.cloud = `${this.lowCloudType} at ${altitudeFormatter(
          altitude
        )} feet`;
      } else if (altitude >= 57 && altitude <= 70) {
        this.middleCloudAltitude = altitude;
        switch (cloud.type) {
          case "NCD":
            this.totClouds = this.totClouds + 0;
            this.middleCloudType = "No significant cloud";
            break;
          case "FEW":
            this.totClouds = this.totClouds + 1;
            this.middleCloudType = "Few clouds";
            break;
          case "SCT":
            this.totClouds = this.totClouds + 3;
            this.middleCloudType = "Scattered clouds";
            break;
          case "BKN":
            this.totClouds = this.totClouds + 5;
            this.middleCloudType = "Broken clouds";
            break;
          case "OVC":
            this.totClouds = this.totClouds + 8;
            this.middleCloudType = "Overcast clouds";
            break;
          case "CLR":
            this.totClouds = this.totClouds + 0;
            this.middleCloudType = "No cloud cover detected below the 12,000 ft";
            break;
          default:
            this.middleCloudType = "No significant cloud";
            this.totClouds = 0;
            break;
        }
        if (cloud.repr.includes("///")) {
          const new_cloud_repr = cloud.repr.replace("///", "")
          console.log(new_cloud_repr)
          qs("#medium-cover").innerHTML = new_cloud_repr;
        } else {
          qs("#medium-cover").innerHTML = cloud.repr;
        }
        qs("#medium-cover").parentNode.dataset.cloud = `${this.middleCloudType} at ${altitudeFormatter(
          altitude
        )} feet`;
      } else if (altitude > 70) {
        this.highCloudAltitude = altitude;
        switch (cloud.type) {
          case "NCD":
            this.totClouds = this.totClouds + 0;
            this.highCloudType = "No significant cloud";
            break;
          case "FEW":
            this.totClouds = this.totClouds + 1;
            this.highCloudType = "Few clouds";
            break;
          case "SCT":
            this.totClouds = this.totClouds + 3;
            this.highCloudType = "Scattered clouds";
            break;
          case "BKN":
            this.totClouds = this.totClouds + 5;
            this.highCloudType = "Broken clouds";
            break;
          case "OVC":
            this.totClouds = this.totClouds + 8;
            this.highCloudType = "Overcast clouds";
            break;
          case "CLR":
            this.totClouds = this.totClouds + 0;
            this.highCloudType = "No cloud cover detected below the 12,000 ft";
            break;
          default:
            this.highCloudType = "No significant cloud";
            this.totClouds = 0;
            break;
        }
        if (cloud.repr.includes("///")) {
          const new_cloud_repr = cloud.repr.replace("///", "")
          console.log(new_cloud_repr)
          qs("#high-cover").innerHTML = new_cloud_repr;
        } else {
          qs("#high-cover").innerHTML = cloud.repr;
        }
        qs("#high-cover").parentNode.dataset.cloud = `${this.highCloudType} at ${altitudeFormatter(
          altitude
        )} feet`;
      }

    });

    // station center otkas
    switch (this.totClouds) {
      case 0:
        this.skyRes = "OTKAS---Station-plotted";
        this.skyHover = "no total cloud information is provided";
        break;
      case 1:
        this.skyRes = "1-OTKAS";
        this.skyHover = "1 okta or less of sky covered";
        break;
      case 2:
        this.skyRes = "2-OTKAS";
        this.skyHover = "2 oktas of sky covered";
        break;
      case 3:
        this.skyRes = "3-OTKAS";
        this.skyHover = "3 oktas of sky covered";
        break;
      case 4:
        this.skyRes = "4-OTKAS";
        this.skyHover = "4 oktas of sky covered";
        break;
      case 5:
        this.skyRes = "5-OTKAS";
        this.skyHover = "5 oktas of sky covered";
        break;
      case 6:
        this.skyRes = "6-OTKAS";
        this.skyHover = "6 oktas of sky covered";
        break;
      case 7:
        this.skyRes = "7-OTKAS";
        this.skyHover = "7 oktas of sky covered";
        break;
      case 8:
        this.skyRes = "8-OTKAS";
        this.skyHover = "8 oktas of sky covered";
        break;
    }

    if (this.totClouds > 8) {
      this.skyRes = "8-OTKAS";
      this.skyHover = "8 oktas of sky covered";
    }

    this.populate(qs("#wind"), this.windSpeedRes, this.windDirection + "° " + this.windSpeed + " kt");
    this.populate(qs("#newsky-coverage"), this.skyRes, this.skyHover);
    this.populate(qs("#present-weather"), this.weatherRes, this.weatherHover);
    this.populate(qs("#barometric-tendency"), this.tendencyRes, this.tendencyHover);

    qsa(".js_symbol text").forEach((el, i) => {
      el.setAttribute(`data-scrabled-${i}`, null);

      Scrambler({
        target: `[data-scrabled-${i}]`,
        random: [1800, 1800],
        text: el.textContent,
      });
    });


    gsap.from(
      this.flag,
      {
        attr: { transform: `rotate(${0}, 215, 215)` },
        duration: 3,
      },
      {
        attr: { transform: `rotate(${this.windDirection}, 215, 215)` },
        duration: 3,
        ease: "Power3.easeInOut",
      }
    );

    setTimeout(() => {
      gsap.to(qs(".parent-svg"), {
        "-webkit-filter": "blur(0px)",
        duration: 0.5,
        ease: "Power3.easeOut",
      });
    }, 1800);
  };

  populate(target, value, hover) {
    console.log('here')
    target.setAttribute("href", `symbol-defs3.svg#new_${value}`);
    target.parentNode.setAttribute("data-value", hover);

    if (target.id === "newsky-coverage") {
      const square = qs(["#square-delete-me"]);
      const triangle = qs(["#triangle-delete-me"]);
      if (value === "OTKAS---Station-plotted") {
        triangle.style.fillOpacity = "0";
        square.style.fillOpacity = "1";
      } else {
        console.log(target, value, hover)
        square.style.fillOpacity = "0";
        triangle.style.fillOpacity = "1";
      }
    }
  }
}
