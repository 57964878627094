import { qs, qsa } from "../utils/lib";


export default class Download {
  constructor(el) {
    this.download_btn = qs(".js-download-button", el);
  }

  init() {
    this.download();
  }

  download() {
    this.download_btn.addEventListener("click", () => {
      SaveSVG.save("parent-svg-5", "style.css", "symbol-defs3.svg");
    });
  }
}
